import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Section, Title, Text, Span } from "../../components/Core";
import imgPhoto from "../../assets/image/jpg/portfolio-about.jpg";
import imgSignLight from "../../assets/image/png/signature.png";

const LinkSocial = styled.a`
  color: ${({ theme }) => theme.colors.light} !important;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 13px;
  letter-spacing: 1.63px;
  transition: 0.4s;
  &:hover {
    color: ${({ theme }) => theme.colors.light} !important;
    transform: translateY(-5px);
  }
`;

const About = ({ hero = true, bg = "dark", ...rest }) => {
  return (
    <>
      <Section hero={hero} bg={bg} {...rest}>
        <Container>
          <Row className="align-items-center">
            {/* <Col lg="6">
              <div>
                <img src={imgPhoto} alt="folio" className="img-fluid" />
              </div>
            </Col> */}
            <Col lg="6">
              <div className="pl-lg-4 pt-5 pt-lg-0">
                <Title color="light" variant="secSm">
                  Maresa
                </Title>
                <Text
                  color="light"
                  className="mt-3 mt-lg-5"
                  css={`
                    line-height: 1.5;
                  `}
                >
                 Maresa Gerlach, based in Vienna, is a dedicated collage artist committed to analog techniques, eschewing digital tools to authentically connect with her materials. As a student of Conservation and Restoration at the Akademie der Bildenden Künste Vienna with a focus on wood, Maresa marries her passion for preserving historical artifacts with her artistic endeavors. 
                </Text>
                <Text
                  color="light"
                  className="mt-3 mt-lg-5"
                  css={`
                    line-height: 1.5;
                  `}
                >
                 Drawing from her academic pursuits and the world at large, she crafts unique collages that embody the spirit of preservation and invention. Whether she's restoring antiques or creating new pieces, Maresa's work is a celebration of her commitment to both history and contemporary artistry.
                </Text>
                {/* <Text
                  color="light"
                  className="mt-3 mt-lg-5"
                  css={`
                    line-height: 1.5;
                  `}
                >
                  Whether she's working to restore a cherished antique or designing a bold new piece of furniture,
                  Maresa's dedication to her craft is evident in every aspect of her work. Her commitment to preserving
                  the past while pushing the boundaries of what is possible is truly inspiring.
                </Text> */}
                {/* <Text color="light" className="mt-4">
                  I strive for a minimal and beautiful design.
                </Text> */}
                <div className="mt-4">
                  <Text color="light">Email</Text>

                  <Text variant="p">
                    <a href="mailto:maresa@maresa.at" className="font-weight-bold">
                      <Span color="light">maresa@maresa.at</Span>
                    </a>
                  </Text>
                </div>
                {/* <div className="mt-5">
                  <img src={imgSignLight} alt="sign" className="img-fluid" />
                </div>
                <Text color="light" className="mt-3 font-weight-bold">
                  Bruce Ryan
                </Text>
                <Text color="light" variant="small">
                  Founder of Folio Digital
                </Text>
                <div className="mt-5 d-flex">
                  <LinkSocial
                    href="https://www.dribbble.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mr-3"
                  >
                    Dribble
                  </LinkSocial>
                  <LinkSocial
                    href="https://www.twitter.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className=" mr-3"
                  >
                    Twitter
                  </LinkSocial>
                  <LinkSocial
                    href="https://www.facebook.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mr-3"
                  >
                    Facebook
                  </LinkSocial>
                  <LinkSocial
                    href="https://www.instagram.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="font-weight-bold"
                  >
                    Instagram
                  </LinkSocial>
                </div> */}
              </div>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default About;
